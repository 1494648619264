/* eslint-disable */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable react/prop-types */

import React, { useEffect, useState } from "react";
import { Avatar, Box, Button, CircularProgress, Grid } from "@mui/material";
import { makeStyles } from "@material-ui/styles";
import Lightbox from "react-image-lightbox";
import moment from "moment";
import classnames from "classnames";
import Header from "../../binsControl/header";
import { PackListItem } from "../../binsControl/binCard/BinItem";
import AdjustmentsHistory from "./adjustmentsHistory";
import { LotDates } from "./lotDates";
import { MenuOptionsEdit } from "./menuOptionsEdit";
import { useModal } from "../../app/utils/useModal";
import { withModal } from "../../../utils/withModal";
import { CommitedOrders } from "../../app/products/commited/CommitedOrders";
import { calcDateColor } from "./stockConditions";
// import { CommentsList } from '../../binsControl/binCard/Comments/CommentsList'
// import { ModalCommentsFalse } from '../../binsControl/binCard/Comments/utils/ModalCommentsFalse'
// import ArchiveIcon from '@material-ui/icons/Archive';
// import { Fab } from "@material-ui/core";
// import { db } from "../../../firebase/database";

const useStyles = makeStyles((theme) => ({
  mainBoxProduct: {
    maxWidth: 1200,
    marginTop: 20,
    margin: "auto",
    boxShadow: "1px 1px 1px 2px rgba(0,0,0,0.1)",
    borderRadius: 20,
    background: "#fff",
  },

  productImage: {
    width: 90,
    height: 90,
    objectFit: "contain",
  },
  productDetail: {
    position: "relative",
    right: 5,
  },
  productName: {
    width: "100%",
    fontSize: "1.2rem",
    fontWeight: "bold",
    textDecoration: "underline",
    textOverflow: "ellipsis",
    [theme.breakpoints.down(330)]: {
      maxWidth: 280,
    },
  },
  binBox: {
    backgroundColor: "#cfd9c0",
    cursor: "pointer",
    borderRadius: 5,
    border: "1px solid black",
    padding: ".2rem .5rem",
    maxWidth: 320,
    // fontSize: '0.8rem',
  },
  binHiglighted: {
    border: "2px solid #3bd49e",
    backgroundColor: "#FFF",
  },
  binButton: {
    padding: 20,
    margin: 4,
    background: "#fff",
    boxShadow: "1px 1px 1px 2px rgba(0,0,0,0.1)",
    fontSize: "1rem",
  },
  totalsBox: {
    backgroundColor: "#f1e6bc",
    boxShadow: "1px 1px 1px 2px rgba(0,0,0,0.1)",
    padding: ".2rem .5rem",
    borderRadius: 5,
    marginTop: "2rem",
  },
  quantity: {
    fontWeight: "bold",
    fontSize: "1em",
  },
  lotPackQ: {
    fontSize: "0.6em",
    marginLeft: "4px",
    color: "#5c5c5c",
  },
  listAvatar: {
    marginRight: 10,
  },
  badge: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: ".2rem .5rem",
    backgroundColor: theme.palette.secondary.main,
    color: "#fff",
    fontSize: ".6rem",
    fontWeight: "bold",
  },
  badgeNoStock: {
    backgroundColor: theme.palette.secondary.main,
  },
  badgeStock: {
    backgroundColor: theme.palette.success.main,
  },
}));

export default function ProductCard({ match, history }) {
  const { id } = match.params;
  const classes = useStyles();
  const [data, setData] = useState();
  const [openEditDates, setOpenEditDates] = useModal();

  const [anchorEl, setAnchorEl] = useState(null);
  const openModalOptions = Boolean(anchorEl);
  const setOpenModalOptions = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const setCloseModalOptions = () => {
    setAnchorEl(null);
  };

  const menuActions = {
    editLotDates: () => setOpenEditDates(),
  };

  const handleMenuAction = (action) => {
    menuActions[action]();
  };
  const ModalCommitedProducts = withModal(CommitedOrders);
  const [openModalCommitedProducts, toggleModalCommitedProducts] = useModal();

  // const [modal, setModal] = useState();
  // const [length, setLenght] = useState()
  // const [comments, setComments] = useState()

  const product = data?.product;
  const [lightboxImage, setLightboxImage] = useState("");

  useEffect(() => {
    let active = true;
    if (id) {
      fetch(
        `https://stock-p-c059a3e3d873.herokuapp.com/products/inventory/${id}`,
      )
        .then((response) => response.json())
        // eslint-disable-next-line no-shadow
        .then((data) => {
          if (active) setData(data);
        })
        .catch((e) => active && setData({ error: e }));
    }
    // eslint-disable-next-line no-return-assign
    return () => (active = false);
  }, [id]);

  function Quantity({ q }) {
    return (
      <div>
        <span className={classes.quantity}> {q} </span>
        <PackListItem classes={classes} q={q} pack={product.pack || 1} />
      </div>
    );
  }

  const calcEarliestExpiration = (lots) => {
    if (!lots?.length) return Infinity;

    const validDates = lots
      .filter((lot) => lot.expirationdate !== "-")
      .filter((lot) =>
        moment(lot.expirationdate, "YYYY-MM-DD").isSameOrAfter(moment()),
      )
      .map((lot) => moment(lot.expirationdate, "YYYY-MM-DD").unix());

    return validDates.length ? Math.min(...validDates) : Infinity;
  };

  const orderedBins = data?.bins?.sort(
    (a, b) => calcEarliestExpiration(a.lots) - calcEarliestExpiration(b.lots),
  );

  return data?.error ? (
    <Box>
      <Header title="Producto" history={history} />
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        height="100vh"
        width="100%"
      >
        <div>Error en el Servidor</div>
        <Button onClick={() => window.location.reload(false)}>
          Reintentar
        </Button>
      </Box>
    </Box>
  ) : !product ? (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      height="100vh"
      width="100%"
    >
      <CircularProgress />
    </Box>
  ) : (
    <>
      {lightboxImage && (
        <Lightbox
          animationDuration={0}
          animationDisabled
          mainSrc={lightboxImage}
          onCloseRequest={() => setLightboxImage("")}
        />
      )}

      <Header title={product?.name} history={history} />

      {/* <CommentsList  id={id} data={comments} products={true} /> */}
      {/*    style={{ position: 'relative', left: 30, top:8}}> */}
      {/*    <ArchiveIcon /> */}
      {/* </Fab> */}
      <Box padding={1} className={classes.mainBoxProduct}>
        <Box
          component="div"
          my={2}
          overflow="hidden"
          whiteSpace="nowrap"
          className={classes.productName}
        >
          {product?.name}
        </Box>
        <Box
          display="flex"
          alignItems="center"
          gap={10}
          marginLeft={0}
          className={classes.productDetail}
        >
          <Avatar
            variant="rounded"
            className={classes.productImage}
            src={product?.image}
            onClick={() => setLightboxImage(product?.image)}
          />
          <Box
            display="flex"
            flexDirection="column"
            padding={1}
            paddingRight={0}
          >
            <p
              style={{
                fontWeight: "bold",
                margin: "0",
                textDecoration: "underline",
              }}
            >
              {product?.brandName}
            </p>
            <Box
              style={{
                // maxWidth: 150,
                borderRadius: 5,
                padding: ".2rem .1rem",
                textAlign: "center",
              }}
            >
              {product?.quantity} {product?.unit} - {product?.taste}
            </Box>
            <Box
              className={classnames({
                [classes.badge]: true,
                [classes.badgeNoStock]: !!product?.nostock,
                [classes.badgeStock]: !product?.nostock,
              })}
            >
              {product?.nostock === true ? "SIN STOCK" : "EN STOCK"}
            </Box>
            {!product?.active && (
              <Box
                className={classnames([classes.badgeNoStock, classes.badge])}
              >
                No Activo
              </Box>
            )}
          </Box>
        </Box>
        <Box margin={0} className={classes.totalsBox}>
          <Box display="flex" justifyContent="space-around">
            <Grid spacing={2} width={"40%"} columns={2} container>
              <Grid xs={6} item>
                <Box display="flex" gap={4}>
                  <div style={{ fontSize: "1rem", margin: ".2rem" }}>
                    En bin:
                    <Quantity q={data?.totals?.onbin} />
                  </div>
                  <Box sx={{ display: "flex", gap: 1 }}>
                    <div
                      style={{
                        fontSize: "1rem",
                        margin: ".2rem",
                      }}
                    >
                      <Button
                        onClick={toggleModalCommitedProducts}
                        size="small"
                        variant="contained"
                        color="primary"
                      >
                        Reservados:
                      </Button>{" "}
                      <Quantity q={data?.totals?.commited} />
                    </div>
                  </Box>
                </Box>
                <ModalCommitedProducts
                  productID={id}
                  open={openModalCommitedProducts}
                  handleClose={toggleModalCommitedProducts}
                />
              </Grid>
              <Grid xs={6} item>
                <Box display="flex" gap={4}>
                  <div style={{ fontSize: "1rem", margin: ".2rem" }}>
                    Picking:
                    <Quantity q={data?.totals?.picking} />
                  </div>
                  <div style={{ fontSize: "1rem", margin: ".2rem" }}>
                    Depósito:
                    <Quantity q={data?.totals?.deposito} />
                  </div>
                </Box>
              </Grid>
            </Grid>

            <LotDates
              shelflife={product?.shelflife}
              productID={product?.objectID}
              openEditDates={openEditDates}
              setOpenEditDates={setOpenEditDates}
            />
            <MenuOptionsEdit
              handleAction={handleMenuAction}
              handleClick={setOpenModalOptions}
              handleClose={setCloseModalOptions}
              open={openModalOptions}
              anchorEl={anchorEl}
            />
          </Box>
        </Box>
        <Grid container>
          <Grid
            xs={12}
            sm={6}
            item
            container
            spacing={2}
            direction="column"
            justifyContent="flex-start"
            alignItems="center"
            sx={{ marginTop: 1 }}
          >
            {(orderedBins || [])?.map((bin, index) => (
              <Grid
                item
                key={bin.id}
                className={[
                  classes.binBox,
                  index === 0 && classes.binHiglighted,
                ]}
                sx={{ marginTop: 1 }}
                onClick={() => history.push("/binsCheck/" + (bin?.id || ""))}
              >
                <Box
                  display="flex"
                  justifyContent="space-around"
                  alignItems="center"
                >
                  <Box display="flex" flexDirection="column">
                    <p
                      style={{
                        fontWeight: "bold",
                        fontSize: "1.2rem",
                        margin: 0,
                      }}
                    >
                      {bin.id}
                    </p>
                    <p
                      style={{
                        margin: 0,
                        fontSize: ".5rem",
                        marginLeft: ".2rem",
                      }}
                    >
                      {bin.type}
                    </p>
                  </Box>
                  {bin.lots?.length > 1 && <Quantity q={bin.q} />}
                </Box>
                <Box style={{ margin: "auto", width: "fit-content" }}>
                  {bin.lots?.map((lotData) => (
                    <Box key={lotData.expirationdate} display="flex">
                      {(lotData.expirationdate || "").match(
                        /\d\d\d\d-\d\d-\d\d/,
                      ) && (
                        <p
                          style={{
                            margin: 0,
                            color: calcDateColor(product?.shelflife, lotData.expirationdate),
                          }}
                        >
                          VTO
                          {moment(lotData.expirationdate).format(
                            "DD/MM/YYYY",
                          ) || "-"}
                        </p>
                      )}
                      <div
                        style={{
                          margin: "0 0 0 20px",
                          paddingLeft: ".2rem",
                          fontWeight: "bold",
                        }}
                      >
                        <Quantity q={lotData.q} />
                      </div>
                    </Box>
                  ))}
                </Box>
              </Grid>
            ))}
          </Grid>
          <Grid item xs={12} sm={6}>
            <AdjustmentsHistory
              inventoryAdjustments={data?.inventoryAdjustments}
              product={product}
            />
          </Grid>
        </Grid>
      </Box>
    </>
  );
}
