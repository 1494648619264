import { useEffect,useRef } from 'react';
const DEFAULT_TITLE = "Vívet Market"
export const useChangeTitle = (title=DEFAULT_TITLE) => {
  
  const documentDefined = typeof document !== 'undefined';
  const originalTitle = useRef(documentDefined ? document.title : null);

  useEffect(() => {
    if (!documentDefined) return;
    if(!title) return document.title = DEFAULT_TITLE;
    if (document.title !== title) document.title = title;

    return () => {
      document.title = originalTitle.current;
    };
  }, [title]);
};
