import moment from "moment";

const conditions = [
    {
      from: 0,
      to: 30,
      min: 0.25,
      alert: 0.3,
    },
    {
      from: 30,
      to: 90,
      min: 0.15,
      alert: 0.2,
    },
    {
      from: 90,
      to: 365,
      min: 0.1,
      alert: 0.2,
    },
    {
      from: 365,
      to: 1000000,
      min: 0.08,
      alert: 0.2,
    },
  ];

  export const calcDateColor = (shelflife,expirationDate) => {
    if(!expirationDate) return 'black'


    const cond = conditions.find(
      (a) => a.from <= shelflife && a.to > shelflife,
    );

    const minDate = moment()
      .add(cond?.min * shelflife, "days")
      .startOf("day");

    const alertDate = moment()
      .add(cond?.alert * shelflife, "days")
      .startOf("day");

    const isMin = moment(expirationDate, 'YYYY-MM-DD').isBefore(minDate);
    const isAlert = moment(expirationDate, 'YYYY-MM-DD').isBefore(alertDate);

    const colors = {
      [isAlert]: "orange",
      [isMin]: "red",
    };

    return colors[true] || "black";
  };