import useStyles from "../../components/Header/styles";
import {
  AppBar,
  IconButton,
  Menu,
  MenuItem,
  Toolbar,
  Typography,
} from "@material-ui/core";
import { ArrowBack as ArrowBackIcon } from "@material-ui/icons";
import React from "react";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import { useHistory } from "react-router-dom";
import { useLocationQuery } from "../../utils/useLocationQuery";

export function HeaderMenu() {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const { changeQuery, parsedHash } = useLocationQuery();
  const isDeleteTasks = parsedHash?.deleteTasks;
  const history = useHistory();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const toggleDeleteTasks = () => {
    handleClose();

    changeQuery({ deleteTasks: !isDeleteTasks });
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const openTasks = () => {
    handleClose();
    history.push(`/roadmaps/tasks`);
  };

  const openDownloadCSV = () => {
    handleClose();
    history.push(`/roadmaps/downloadCSV`);
  };

  const showDeleteTasks = history?.location?.pathname?.includes("loadBaskets");

  return (
    <div>
      <IconButton
        aria-label="display more actions"
        edge="end"
        color="inherit"
        onClick={handleClick}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        getContentAnchorEl={null}
        disableScrollLock={true}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {!showDeleteTasks && <MenuItem onClick={openTasks}>Tareas</MenuItem>}
        {!showDeleteTasks && (
          <MenuItem onClick={openDownloadCSV}>Descargar CSV</MenuItem>
        )}
        {showDeleteTasks && (
          <MenuItem onClick={toggleDeleteTasks}>
            {isDeleteTasks ? "Cancelar" : "Eliminar orden"}
          </MenuItem>
        )}
      </Menu>
    </div>
  );
}

export function PageHeader({
  icon = <ArrowBackIcon />,
  backHandler,
  title = "Vivet Market",
}) {
  const history = useHistory();
  const classes = useStyles();
  return (
    <AppBar position="fixed" className={classes.appBar}>
      <Toolbar className={classes.toolbar}>
        <IconButton
          color="inherit"
          onClick={backHandler || (() => history.goBack())}
        >
          {icon}
        </IconButton>
        <Typography
          variant="h6"
          className={classes.logotype}
          style={{ flexGrow: 1 }}
        >
          {title}
        </Typography>
        <HeaderMenu />
      </Toolbar>
    </AppBar>
  );
}
