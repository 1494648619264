/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
import React from "react";
import { Button } from "@material-ui/core";

// styles
import useStyles from "./styles";

// components
import { Typography } from "../Wrappers";
import { useChangeTitle } from "../Utils/useChangeTitle";
import { useAreaDataContext } from "../../context/AreaContext";

export default function PageTitle(props) {
  const classes = useStyles();
  const areaData = useAreaDataContext();
  useChangeTitle(areaData.panel[props?.index]?.title || props?.title);

  return (
    <div className={classes.pageTitleContainer}>
      <Typography className={classes.typo} variant="h1" size="sm">
        {props.title}
      </Typography>
      {props.button && (
        <Button
          classes={{ root: classes.button }}
          variant="contained"
          size="large"
          color="secondary"
        >
          {props.button}
        </Button>
      )}
    </div>
  );
}
