import { makeStyles } from "@material-ui/styles";
import React, { useRef, useState, useEffect } from "react";
import Dropzone from "react-dropzone";
import * as Icons from "@material-ui/icons";
import DeleteIcon from "@material-ui/icons/Delete";
import CircularProgress from "@material-ui/core/CircularProgress";
import Button from "@material-ui/core/Button";
import CameraAltIcon from '@material-ui/icons/CameraAlt';
import ImageIcon from '@material-ui/icons/Image';
import uploadToDrive from "./Upload";
import uploadToImgur from "../Imgur";
import UploadToFirebase from "../../firebase/Upload";

const useStyles = makeStyles((theme) => ({
  inputUploadContainer: {
    position: "relative",
  },
  wrapper: {
    margin: theme.spacing(0),
    position: "relative",
    width: "100%",
    border: "0",
    color: "#4A4A4A",
    padding: "9px",
    borderRadius: "4px",
    cursor: "text",
    display: "inline-flex",
    fontSize: "1rem",
    boxSizing: "border-box",
    alignItems: "center",
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontWeight: "400",
    lineHeight: "1.1876em",
    letterSpacing: "0.00938em",
    borderWidth: "thin",
    borderStyle: "solid",
    borderColor: "#d5d5d5",
  },
  inputLabel: {
    backgroundColor: "white",
    paddingLeft: "5px",
    paddingRight: "5px",
    zIndex: "1",
    pointerEvents: "none",
    transformOrigin: "top left",
    top: "0",
    left: "18px",
    position: "absolute",
    display: "block",
    color: "#6E6E6E",
    padding: "0",
    fontSize: "0.8rem",
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontWeight: "400",
    letterSpacing: "0.00938em",
  },
  uploadSection: {
    width: "100%",
    padding: theme.spacing(1),
    cursor: "pointer",
    "& div": {
      textOverflow: "ellipsis",
      overflow: "hidden",
    },
  },
  dropHere: {
    textAlign: "center",
  },
  cameraButton: {
    marginLeft: theme.spacing(1),
  },
  hiddenInput: {
    display: 'none'
  },
  buttonContainer: {
    display: 'flex',
    gap: '8px',
    flexWrap: 'wrap'
  }
}));

export default function InputUpload({
  accept,
  onUploaded,
  title,
  folderId,
  filename,
  fileId,
  uploadTo,
  preview,
  fullPath,
  textDescription,
  useCamera = false
}) {
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [dragOver, setDragOver] = useState(false);
  const cameraInputRef = useRef(null);
  const galleryInputRef = useRef(null);
  
  const classes = useStyles();

  useEffect(() => {
    let timer;
    if (success) {
      timer = setTimeout(() => setSuccess(false), 2000);
    }
    return () => clearTimeout(timer);
  }, [success]);

  const handleUpload = async (files) => {
    if (!loading && files.length > 0) {
      setLoading(true);
      setDragOver(false);
      try {
        let result;
        const file = files[0];
        
        switch (uploadTo) {
          case "imgur":
            result = await uploadToImgur(files);
            break;
          case "firebase":
            result = await UploadToFirebase({ file, fullPath });
            break;
          default: // "drive" or undefined
            result = await uploadToDrive(
              { files, fileIdToDelete: fileId || undefined },
              folderId
            );
        }
        
        setSuccess(true);
        onUploaded?.(result, file.name || "");
      } catch (error) {
        console.error("Upload error:", error);
      } finally {
        setLoading(false);
      }
    }
  };

  const handleFileInput = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      handleUpload([e.target.files[0]]);
      e.target.value = ''; // Reset input
    }
  };

  const deleteHandler = (e) => {
    e.stopPropagation();
    if (!uploadTo || uploadTo === "drive") {
      uploadToDrive({ fileIdToDelete: fileId });
    }
    onUploaded?.("", "");
  };

  return (
    <div className={classes.inputUploadContainer}>
      <label className={classes.inputLabel}>
        {title} <i className="fa fa-paperclip" />
      </label>
      <div className={classes.wrapper}>
        {!useCamera ? (
          <Dropzone
            onDrop={handleUpload}
            onDragEnter={() => setDragOver(true)}
            onDragLeave={() => setDragOver(false)}
            noClick={!!preview}
          >
            {({ getRootProps, getInputProps }) => (
              <div {...getRootProps()} className={classes.uploadSection}>
                <input {...getInputProps()} accept={accept} />
                
                {dragOver ? (
                  <div className={classes.dropHere}>
                    ¡Suelta el archivo aquí!
                  </div>
                ) : loading ? (
                  <div>Subiendo archivo...</div>
                ) : success ? (
                  <div>
                    ¡Subido correctamente! <Icons.Check />
                  </div>
                ) : preview || filename ? (
                  <div className={classes.buttonContainer}>
                    <span>{preview || filename}</span>
                    <Button
                      variant="contained"
                      color="secondary"
                      startIcon={<DeleteIcon />}
                      onClick={deleteHandler}
                    >
                      Eliminar
                    </Button>
                  </div>
                ) : (
                  <div>
                    {textDescription || "Arrastra archivos o haz clic para seleccionar"}
                  </div>
                )}
                
                {loading && <CircularProgress size={24} />}
              </div>
            )}
          </Dropzone>
        ) : (
          <div className={classes.buttonContainer}>
            {/* Input para la cámara */}
            <input
              type="file"
              ref={cameraInputRef}
              className={classes.hiddenInput}
              accept="image/*"
              capture="environment"
              onChange={handleFileInput}
            />
            
            {/* Input para la galería */}
            <input
              type="file"
              ref={galleryInputRef}
              className={classes.hiddenInput}
              accept="image/*"
              onChange={handleFileInput}
            />
            
            <Button
              variant="contained"
              color="primary"
              startIcon={<CameraAltIcon />}
              onClick={() => cameraInputRef.current?.click()}
              disabled={loading}
            >
              Tomar foto
            </Button>

            <Button
              variant="outlined"
              color="primary"
              startIcon={<ImageIcon />}
              onClick={() => galleryInputRef.current?.click()}
              disabled={loading}
            >
              Elegir de galería
            </Button>

            {(loading || success || preview || filename) && (
              <div>
                {loading ? (
                  <div>Subiendo archivo... <CircularProgress size={24} /></div>
                ) : success ? (
                  <div>¡Subido correctamente! <Icons.Check /></div>
                ) : preview || filename ? (
                  <div className={classes.buttonContainer}>
                    <span>{preview || filename}</span>
                    <Button
                      variant="contained"
                      color="secondary"
                      startIcon={<DeleteIcon />}
                      onClick={deleteHandler}
                    >
                      Eliminar
                    </Button>
                  </div>
                ) : null}
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
}