import { CircularProgress } from "@material-ui/core"
import { useGetRoadmapsToLoad } from "../hooks/use-get-roadmaps-to-load"
import { Box, Button, Card, CardContent, Grid, Typography } from "@mui/material"
import palletImg from "../../../images/palletBox.jpg";
import { memo } from "react";
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import { Link } from 'react-router-dom'

export default function LoadOrders() {
    const [roadmaps, isLoading] = useGetRoadmapsToLoad()

    if (isLoading && !roadmaps?.length) return <CircularProgress />

    return (
        <Box sx={{ flexGrow: 1, p: 1 }}>
            <Grid container spacing={1}>
                {roadmaps.map((roadmap) => (
                    <RoadmapCard key={roadmap.objectID} roadmap={roadmap} />
                ))}

            </Grid>
        </Box>
    )
}

const RoadmapCardComponent = ({ roadmap }) => {
    return (
        <Grid item xs={12} sm={6} md={4} lg={3}>
            <Card
                sx={{
                    borderRadius: 1,
                    height: '140px',
                    '&:hover': {
                        boxShadow: 6,
                        transform: 'scale(1.02)',
                        transition: 'all 0.2s ease-in-out'
                    }
                }}
            >
                <CardContent sx={{ height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'space-between', p: 2 }}>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start' }}>
                        <Box>
                            <Typography variant="h6" component="div" sx={{ fontWeight: 'bold', fontSize: '.8rem' }}>
                                {roadmap.shipping}
                            </Typography>
                            <Typography color="text.secondary" sx={{ fontSize: '0.75rem' }}>
                                {roadmap.date}
                            </Typography>
                        </Box>
                        <Typography
                            sx={{
                                color: 'primary.main',
                                fontWeight: 'medium',
                                fontSize: '0.875rem'
                            }}
                        >
                            {roadmap.itemsQ} paradas
                        </Typography>
                    </Box>

                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mt: 1 }}>
                        <Box>
                            <Typography variant="body2" sx={{ fontSize: '0.75rem' }}>
                                {roadmap.driver}
                            </Typography>
                            <Typography variant="body2" sx={{ fontSize: '0.75rem' }}>
                                {roadmap.vehicleType}
                            </Typography>
                        </Box>
                        <Box sx={{ display: 'flex', gap: 1 }}>
                            <Button component={Link} to={`/roadmaps/${roadmap.objectID}/loadBaskets/truck`} variant="outlined">
                                <LocalShippingIcon size={24} />
                            </Button >
                            <Button component={Link} to={`/roadmaps/${roadmap.objectID}/loadBaskets/pallets`} variant="outlined">
                                <img src={palletImg} width={40} />
                            </Button>
                        </Box>
                    </Box>
                </CardContent>
            </Card>
        </Grid>

    )
}

const RoadmapCard = memo(RoadmapCardComponent)