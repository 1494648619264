import { Chip, Stack } from "@mui/material";
import { formatCountryCurrency } from "../../../../utils/formatCountryCurrency";
import { OrderShipmentPresentation } from "./[order]";
import { useMongoAggregate } from "../../../../utils/mongoAggregate/useMongoAggregate";
import { useSearchParams } from "../../hooks/useSearchParams";
import { TableProductCard } from "../../components/shipment-managment/[id]/table-product-card";
import { useHistory } from "react-router-dom";
import { useContext, useEffect } from "react";
import { SellerContext } from "../../context/sellerContext";
import { useDocumentData } from "../../../../firebase/database";

export default function OrderShipment() {
  const { getParam } = useSearchParams();
  const idParam = getParam("id");
  const history = useHistory();
  const { sellerSelected } = useContext(SellerContext);
  const [purchaseData, purchaseLoading] = useDocumentData("purchaseOrders", idParam)

      useEffect(() => {
        if (purchaseLoading || !purchaseData) return;
        history.push(
          `/providers/purchaseOrders/${encodeURI(purchaseData?.name)}?id=${idParam}`,
    );
  }, [purchaseLoading]);

  
  const [inventoryData, inventoryLoading] = useMongoAggregate({
    index: "inventoryAdjustments",
    refetchOnWindowFocus: false,
    aggregate: [
      {
        $match: { 
          "data.objectID":
          !purchaseLoading && purchaseData &&
            purchaseData.received.inventoryAdjustment,
        },
      },
      {
        $project: {
          "data.controlReason":1,
          "data.items": 1,
          "data.inventorynumbers": 1,
          "data.invoicedUnits": 1
        },
      },

    ],
  });
  

  const inventoryNumberIds =
    inventoryData &&
    inventoryData.length > 0 &&
    inventoryData[0].data?.inventorynumbers;

  const [inventorynumbersData, inventorynumbersLoading] = useMongoAggregate({
    index: "inventorynumbers",
    aggregate: [
      {
        $match: {
          "data.objectID": {
            $in: inventoryNumberIds && inventoryNumberIds,
          },
        },
      },
      {
        $project: {
          expirationdate: "$data.expirationdate",
          product: "$data",
        },
      },
    ],
  });

  const purchaseOrderItems =
    !purchaseLoading && purchaseData && 
    Object.hasOwn(purchaseData, "items")
      ? Object.keys(purchaseData.items)
      : [];

  const [sellerData, sellerDataLoading] = useMongoAggregate({
    index: "sellers",
    refetchOnWindowFocus: false,
    aggregate: [
      {
        $match: {
          "data.objectID": sellerSelected,
        },
      },
      {
        $project: {
          "data.availableSchedule": 1,
          "data.objectID": 1,
        },
      },
    ],
  });

  const [itemsData, itemsLoading] = useMongoAggregate({
    index: "products",
    aggregate: [
      {
        $match: {
          "data.objectID": {
            $in: purchaseOrderItems,
          },
        },
      },
      {
        $project: {
          "data.objectID": 1,
          "data.pack": 1,
          "data.brandName": 1,
          "data.price_by": 1,
          "data.name": 1,
          "data.q": 1,
          "data.tags": 1,
          "data.unit": 1,
          "data.thumbnail": 1,
          "data.image": 1,
          "data.weight": 1,
          "data.sellerCode": 1,
        },
      }
    ],
  });
  // const getLotOfItem
  const inventoryProductIDs =
    !inventorynumbersLoading &&
    inventorynumbersData &&
    inventorynumbersData.map((el) => el.product.product);

  const getItemExpirationDateAndQuantityAndWeight = (itemID) => {
    const result = inventorynumbersData?.filter((el) => {
      return el.product.product === itemID || el?.product === itemID;
    });
    if (result?.length <= 0 || !result) return null;
    return result.map((r) => [r.product?.received, r.expirationdate, r.product.weight?.received]);
  };

  const getPurchaseOrderItemQuantity = (id) =>
    purchaseData.items[id]?.q;

  const checkItemsPack = () =>
    //Checks if pack item property is greater than 0
    !itemsLoading &&
    itemsData
      ?.map((e) => Object.hasOwn(e.data, "pack") && e.data.pack)
      ?.reduce((a, b) => a + b, 0) <= 0
      ? false
      : true;

  const checkItemsPrice = () =>
    !purchaseLoading && purchaseData && 
    Object.values(purchaseData?.items || {})
      .map(
        (i) =>
          Object.hasOwn(i, "purchasePrice") &&
          !Number.isNaN(i.purchasePrice) &&
          i.purchasePrice,
      )
      .reduce((a, b) => a + b, 0) <= 0
      ? false
      : true;

  const checkItemsIVA = () =>
    !purchaseLoading &&
    purchaseData &&
    Object.values(purchaseData.items || {})
      .map((i) => Object.hasOwn(i, "iva") && !Number.isNaN(i.iva) && i.iva)
      .reduce((a, b) => a + b, 0) <= 0
      ? false
      : true;

  const calcBulk = (q, pack) =>
    q / pack === Infinity || isNaN(q / pack) ? "" : q / pack;

  const getProductById = id =>  itemsData.find(product => product.data.objectID === id).data
  const columnVisibilityModel = {
    iva: checkItemsIVA(),
    subtotal: checkItemsPrice(),
    pack: checkItemsPack(),
    bulk: checkItemsPack(),
    purchasePrice: checkItemsPrice(),
    receivedExp:
      !inventoryProductIDs || inventoryProductIDs.length <= 0 ? false : true,
  };
  const columns = [
    { field: "id", headerName: "", width: 1, renderCell: () => null },
    {
      field: "product",
      headerName: "",
      width: 600,
      autoHeight: true,

      renderCell: ({ row: { product } }) => (
        <TableProductCard
          imageSrc={product.imageSrc}
          id={product.id}
          tags={product.tags}
          measurement={product.measurement}
          measurementUnit={product.measurementUnit}
          name={product.name}
          brandName={product.brandName}
          code={product.code}
        />
      ),
    },
    {
      field: "quantity",
      headerName: "Cantidad / Kg prom. /u",
      type: "number",
      width: 160,
      renderCell: (data, index) => {
        const priceProductIsByWeight  = getProductById(data.row.id).price_by === "weight" 
        const inventoryNumber = getInventoryNumberByProductID(data.row.id)
        const invoicedUnit = inventoryData[0]?.data?.invoicedUnits[data?.row?.id]
        const receivedUnits = inventoryNumber?.reduce((prev, current) => prev + current.product?.received, 0).toFixed(2)

        const averageUnit = isNaN(invoicedUnit) ? false : `${(invoicedUnit / receivedUnits).toFixed(2) }kg/u`
        return (
          <>
            <Stack flexWrap="wrap" gap={1} direction={"row"}>
                <Chip
                  key={data.row.id + index}
                  style={{ fontWeight: 600, color: "#3c4048" }}
                  label={
                   `${data.row.quantity} ${priceProductIsByWeight && averageUnit ? '/ ' + averageUnit : ' u.'}`
                  }
                />
            </Stack>
          </>
        );
      }
    },

    {
      field: "pack",
      headerName: "Pack",
      type: "number",
      width: 80,
    },
    {
      field: "bulk",
      headerName: "Bulto",
      type: "number",
      width: 100,
    },
    {
      field: "receivedExp",
      headerName: "P. recibidos / Vencimiento / kg por lot.",
      sortable: false,
      width: 350,
      renderCell: ({ row: { receivedExp, id } }) => {
        return (
          <>
            {receivedExp && (
              <Stack flexWrap="wrap" gap={1} direction={"row"}>
                {receivedExp?.map((val, i) => (
                  
                
                  <Chip
                    key={id + i}
                    style={{ fontWeight: 600, color: "#3c4048" }}
                    label={`${val[0] || "No especificado"} / ${
                      val[1] || "No especificado"
                    } ${!isNaN(val[2]) ? ("/ "+ val[2] / 1000 + "kg" || "No especificado") : ''}`}
                  />
                ))}
              </Stack>
            )}
          </>
        );
      },
    },
    {
      field: "purchasePrice",
      align: "center",
      headerName: "Precio de compra",
      type: "number",
      width: 150,
    },
    {
      field: "iva",
      headerName: "IVA",
      sortable: false,
      type: "number",
      width: 40,
    },
    {
      field: "subtotal",
      headerName: "Subtotal",
      sortable: false,
      type: "number",
      width: 160,
      renderCell : (el) => {
        return <>
          {isNaN(Number(el.row.subtotal)) ? el.row.subtotal.includes("NaN") ? '' : el.row.subtotal : Number(el.row.subtotal)}
        </>
      }
    },
    {
      field: "total",
      headerName: "Total",
      sortable: false,
      type: "number",
      width: 160,
      renderCell: (el) => {
        
          return !el.row.total || isNaN(el.row.total) ? null : `${formatCountryCurrency(el.row.total, 'ARS')}`
      }
    },
    {
      field: "invoicedUnits",
      headerName: "Unidades/ KG en factura",
      sortable: false,
      type: "number",
      width: 190,
      renderCell: (data) => {
        const priceProductIsByWeight = getProductById(data.row.id).price_by === "weight";
        const invoicedUnit = (inventoryData[0]?.data?.invoicedUnits[data?.row?.id])?.toFixed(2)
        const fallbackMessage = 'No disponible'
        return (
          <>
            {invoicedUnit && <Chip
              style={{ fontWeight: 600, color: "#3c4048" }}
              label={priceProductIsByWeight ? `${(invoicedUnit ? invoicedUnit + " kg" : fallbackMessage)}` : invoicedUnit+" u."}
            />}
            {!invoicedUnit && <Chip
              style={{ fontWeight: 600, color: "#3c4048" }}
              label={fallbackMessage}
            />}
          </>

        )
      }
    },
  ];

  const rows =
    !itemsLoading &&
    !inventorynumbersLoading &&
    itemsData?.map((item) => {
      const productCard = {
        imageSrc: item.data.image,
        name: item.data.name,
        id: item.data.brandName,
        measurement: item.data?.weight ? item.data.weight : null,
        measurementUnit: item.data.unit ? item.data.unit : null,
        code: item.data?.sellerCode,
        brandName: item.data?.brandName,
      };

      const receivedExpDate =
        !inventoryLoading &&
        !inventorynumbersLoading &&
        getItemExpirationDateAndQuantityAndWeight(item.data.objectID);

      return createRow(
        item.data.objectID,
        productCard,
        getPurchaseOrderItemQuantity(item.data.objectID),
        formatCountryCurrency(
          purchaseData.items[item.data.objectID]?.purchasePrice,
        ),
        formatCountryCurrency(
          purchaseData.items[item.data.objectID]?.q *
            purchaseData.items[item.data.objectID]?.purchasePrice,
        ),
        purchaseData.items[item.data.objectID]?.iva
          ? purchaseData.items[item.data.objectID]?.iva * 100 + "%"
          : "21%",
        receivedExpDate,
        item.data.pack,
        calcBulk(
          getPurchaseOrderItemQuantity(item.data.objectID),
          item.data.pack,
        ),
        purchaseData.items[item.data.objectID]?.q *purchaseData.items[item.data.objectID]?.purchasePrice + 
        (21 * purchaseData.items[item.data.objectID]?.q *purchaseData.items[item.data.objectID]?.purchasePrice) / 100,
        'hola'
      );
    });
  
  const getInventoryNumberByProductID = (productID) => inventorynumbersData?.filter(inventorynumber => {
    return inventorynumber.product.product === productID
  });
  return (
    <OrderShipmentPresentation
      loading={
        purchaseLoading &&
        itemsLoading &&
        inventoryLoading &&
        sellerDataLoading &&
        inventorynumbersLoading
          ? true
          : false
      }
      rows={rows}
      columns={columns}
      products={itemsData && itemsData}
      inventorynumbersData={inventorynumbersData}
      colVisibilityModel={columnVisibilityModel}
      sellerData={sellerData && sellerData[0]}
      inventoryAdjustmentData={inventoryData && inventoryData[0]}
      purchaseOrderData={purchaseData && purchaseData}
    />
  );
}

// Table Columns //

const createRow = (
  id,
  product,
  quantity,
  purchasePrice,
  subtotal,
  iva,
  receivedExp,
  pack,
  bulk,
  total,
  invoicedUnits
) => ({
  id,
  product,
  quantity,
  purchasePrice,
  subtotal,
  iva,
  receivedExp,
  pack,
  bulk,
  total,
  invoicedUnits
});
