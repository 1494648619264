import _ from "lodash";
import { useEffect, useState } from "react";
import { useMongoAggregate } from "../../../../../utils/mongoAggregate/useMongoAggregate";

const collectionsWithSpecialNames = ['pricelist']
const defaultVoidMatch = {$match: {}}

// Get rid of those match object that have void values like $match: {}
const filterVoidMatch = (arr) => {
  const arrEntries = arr.length && arr && Object.entries(Object.hasOwn(arr[0], '$match') ? arr[0]["$match"] : {})
  const everyEntryIsVoid = arrEntries.length && arrEntries.every(entry => _.isEmpty(entry[1]));
  if(everyEntryIsVoid || !arrEntries) return null
  return [{$match: Object.fromEntries(arrEntries.filter(e => !_.isEmpty(e[1])) )}]
}

const convertArrayIntoObj = (arr) => arr && Object.assign({}, ...arr)

const facetsToAggregate = (facets, match, defaultFiltersVoid) => {

  return _.chain({
    ...facets,
    ..._.chain(facets)
      .pickBy((v) => v.multiple && match[v.field])
      .mapKeys((v, k) => `${k}_multiple`)
      .value(),
  })
    .mapValues((v, k) => [
      !defaultFiltersVoid && convertArrayIntoObj(filterVoidMatch(matchArray(match, !k.includes("_multiple") && v.field, facets))) || defaultVoidMatch,
      {
        $unwind: `$${v.field}`,
      },
      {
        $group: {
          _id: `$${v.field}`,
          count: { $sum: 1 },
        },
      },
      ...(match[k]
        ? [
            {
              $project: {
                count: 1,
                selected: {
                  $cond: [{ $in: ["$_id", [match[k]].flat()] }, 1, 0],
                },
              },
            },
          ]
        : []),
      {
        $sort: { selected: -1, count: -1, _id: -1 },
      },
      ...(v?.lookup
        ? [
            {
              $lookup: {
                localField: "_id",
                from: collectionsWithSpecialNames.includes(v?.lookup.name.toLowerCase()) ? `p${v?.lookup.name.toLowerCase()}s` : `p${v?.lookup.name}`,
                foreignField: "data.objectID",
                as: k,
                pipeline: [
                  {
                    $project: {
                      [v?.lookup?.field]: `$data.${v?.lookup?.field}`,
                    },
                  },
                ],
              },
            },
          ]
        : []),
      ...(v.limit
        ? [
            {
              $limit: _.max([v.limit, match[k]?.length || 0]),
            },
          ]
        : []),
      ...(v.index
        ? [
            {
              $lookup: {
                localField: "_id",
                from: `p${v.index}`,
                foreignField: "data.objectID",
                as: k,
                pipeline: [
                  {
                    $project: projectToAggregate(v.project),
                  },
                ],
              },
            },
            {
              $project: {
                _id: 1,
                count: 1,
                ...v.project.reduce(
                  (p, a) => ({ ...p, [a]: { $first: `$${v.field}.${a}` } }),
                  {},
                ),
              },
            },
          ]
        : []),
    ])
    .value();
};

const projectToAggregate = (project) =>
  _.chain(project)
    .uniq()
    .reduce((p, a) => ({ ...p, [a]: `$data.${a}` }), {
      _id: 0,
      objectID: "$data.objectID",
    })
    .value();

const matchArray = (match, omit, facets) => {
  return _.isEmpty(_.omit(match, [omit]))
    ? []
    : [
        {
          $match: _.chain(match)
            .omit([omit])
            .mapValues((v, k) =>
              _.isArray(v) && !k.match(/^\$/)
                ? { [facets[k]?.multiple ? "$all" : "$in"]: v }
                : v,
            )
            .value(),
        },
      ];
};

export default function useData({
  index,
  match = {},
  facets = {},
  project = [],
  sort = {},
  facetCount,
  resetData,
  size = 100,
  limit = 100,
  lookup = [],
  defaultDateFilters = {},
  defaultFilters = {},
}) {
  const [items, setItems] = useState([]);
  const mongoProject = projectToAggregate(
    _.concat(
      project,
      _.values(facets).map((f) => f.field),
    ),
  );
  const defaultFiltersVoid = Object.values(defaultFilters).every(e => _.isEmpty(e))

  const matchFiltersVoid = Object.values(match).every(e => _.isEmpty(e))

  const defaultMatch = !defaultFiltersVoid && convertArrayIntoObj(filterVoidMatch(matchArray(
    _.mapKeys(defaultFilters, (v, k) => (k.match(/^\$/) ? k : `data.${k}`)),
  ))) || defaultVoidMatch

  const countMatch = !matchFiltersVoid && convertArrayIntoObj(filterVoidMatch(matchArray({ ...defaultDateFilters, ...match }, "", facets))) || defaultVoidMatch
  const facetMatch = !matchFiltersVoid && convertArrayIntoObj(filterVoidMatch(matchArray({ ...match, ...defaultDateFilters }, "", facets))) || defaultVoidMatch
  const [
    [
      {
        hits: data,
        count: [{ count } = { count: 0 }] = [],
        ...responseFacets
      } = {},
    ] = [],
    loading,
    error,
    { isRefetching },
  ] = useMongoAggregate({
    index,
    enabled: !!size,
    refetchOnWindowFocus: false,
    aggregate: [
      // Este objeto siempre va a estar al tope de la consulta de mongo
       // Arreglar que el objeto de itere y se agrege a este aggregate!!
       defaultMatch,
      {
        $project: mongoProject,
      },
      {
        $facet: {
          hits: [
            facetMatch,
            {
              $sort: sort
            },
            { $limit: size },
            { $skip: facetCount && (size > facetCount || facetCount > size) ? 0 : size - limit },
            ...lookup.map(({ from, localField, as, fields }) => ({
              $lookup: {
                foreignField: "data.objectID",
                pipeline: [
                  {
                    $project: projectToAggregate(fields),
                  },
                ],
                from: collectionsWithSpecialNames.includes(from.toLowerCase()) ? `p${from.toLowerCase()}s` : `p${from}`,
                localField,
                as,
              },
            })),
          ],
          count: [
            // Sacar este match en caso de que dateFilters o match esten vacios
            countMatch,
            { $group: { _id: "", count: { $sum: 1 } } },
          ],
          ...facetsToAggregate(facets, { ...defaultDateFilters, ...match }, matchFiltersVoid),
        },
      },
    ].filter((d) => _.reduce(d, (p, v) => p || !_.isEmpty(v), false)),
  });


  useEffect(() => {
    if(!loading && data){
      const matchHasMultiple = Object.entries(match).every(e => _.isEmpty(e) || _.isEmpty(e[1])) ? false : Object.entries(match).every(match => typeof match[1] !== "string" && match[1].length > 1)
      const sizeGreaterThanCount = size > count
      setItems((prevItems) => {
        let it = ((sizeGreaterThanCount || facetCount > size && !matchHasMultiple) ? [] : size - limit === 0 ? [] : [...prevItems])
          .filter(e => e );
        if(size > count && !Object.values(match).length){
            it = [...prevItems].filter(e => e);
        }
        data.forEach((d, i) => _.set(it, Number(i) + size - limit, d));
        return resetData ? data : it;
      });
    }    
  }, [data, loading, count]);

  return [items, loading || isRefetching, error, count, responseFacets];
}
