import _ from "lodash";
import QueryString from "qs";
import { useHistory } from "react-router-dom";

export const useLocationQuery = () => {
  const history = useHistory();
  const parsedHash = QueryString.parse(
    history.location.search?.replace("?", ""),
  );

  const changeQuery = (query) =>
    history.replace({
      search: QueryString.stringify(
        _.omitBy({ ...parsedHash, ...query }, (a) => a !== 0 && !a),
      ),
    });
  const changeSearchText = (text) =>
    history.replace({
      search: QueryString.stringify(
        _.omitBy({ ...parsedHash, text: text || false }, (a) => !a),
      ),
    });

  const deleteAll = () => {
    history.replace({
      search: "",
    });
  };

  return {
    parsedHash,
    changeQuery,
    changeSearchText,
    deleteAll,
    queryURL: QueryString.stringify(parsedHash),
  };
};
