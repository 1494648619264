import _ from "lodash";

// increment-data_limit: Incrementa el valor del size, se le debe pasar el size y el limite en el action

// handle-sort: Maneja los valores de sort para la tabla, si el campo es de tipo 'date' le añade '_timestamp'

// set-quick-filter: Setea el valor del quickFilter

// set-default-filters: Setea el valor del default value, estos siempre van a estar al tope del a consulta de mongo

// remove-empty-data: Filtra los objectos vacios (?)

const tableRequestReducer = (state, action) => {
    switch(action.type){
      case 'set-quick-filter': {
        const fieldsAsObj = action.fieldsToSearch.reduce((o, key) => ({ ...o, [key]: {
            "$regex": action.nextQuickFilter.join(' '),
            "$options": "i"
        }}), {})
        return { 
          ...state,
          match: {
            ...state.match,
            ...fieldsAsObj
          }
        }
      }
      case 'is-searching': {
        return {...state, isSearching: action.value}
      }
      case 'remove-empty-data': {
        let stateWitRemovedVoidData = JSON.parse(JSON.stringify(state));
        _.reduce(
          stateWitRemovedVoidData,
            (result, value, key) => {
                (typeof v === "object" && _.isEmpty(value)) || !value
                    ? (result = _.omit(result, key))
                    : _.set(result, key, value);
                  return result;
                    },
                  {
                    ...stateWitRemovedVoidData,
                  }
        )
            
          return {
            ...stateWitRemovedVoidData
          }
      }
      case 'delete-default-filter-by-key': {
        return { 
          ...state,
          defaultFilters: {
            ...(_.omit(state.defaultFilters, action.keyToRemove)),
          }
        }
      }
      case 'set-default-filters': {
        if(action.keepPrevius){
          return { 
            ...state,
            defaultFilters: {
              ...state.defaultFilters,
              ...action.nextFilters
            }
          };
        }
        return { 
          ...state,
          defaultFilters: action.nextFilters
        };
      }
      case 'reset-data': {
        return { 
          ...state,
          size: 50,
          resetData: action.value
        };
      }
      case 'increment-data-limit': {
        return { 
          ...state,
          size: action.value
        };
      }
      case 'handle-sort': {
        return {
          ...state,
          sort: action.defaultSort
  
        }
      }
      case 'set-skip': {
        return { 
          ...state,
          skip: action.nextSkip
  
      }
      }

      case 'handle-sort-model': {
        const fieldIsTypeDate = action.fieldType === 'date'
        return { 
          ...state,
          sort: action.sortModel.reduce(
            (p, a) => ({ ...p, [fieldIsTypeDate ? `${a.field}_timestamp` : a.field]: a.sort !== "asc" ? 1 : -1 }),
            {},
          ),
  
      }
      }
      case 'add-match-filter': {
        let stateCloned = JSON.parse(JSON.stringify(state));
        const matchPulled = _.pull([action.currentMatch].flat(), action.id)
        stateCloned.match[action.matchKey] = action.currentMatch
            ? action.wasMatchAlreadyAdded
              ? matchPulled
              : [action.currentMatch, action.id].flat()
            : action.id
        !stateCloned.match[action.matchKey]?.length && (stateCloned.match = _.omit(stateCloned.match, action.matchKey))
        return {
          ...stateCloned
          
        }
      }
      case 'increase-facets-limit': {
        let stateCloned = JSON.parse(JSON.stringify(state));
        stateCloned.facets[action.facetKey].limit = action.limit
        return { 
          ...stateCloned
        }
      }
      case 'set-match-field': {
        let stateCloned = JSON.parse(JSON.stringify(state));
        stateCloned.match[action.field]  = action.value
        return {
          ...stateCloned
        }
      }
      case 'set-sort-field': {
        let stateCloned = JSON.parse(JSON.stringify(state));
        stateCloned.sort[action.field]  = action.value

        return {
          ...stateCloned
        }
      }
  
  }
}

export {tableRequestReducer}