import { Box, Button, CircularProgress, Typography } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { useMongoAggregate } from "../../../utils/mongoAggregate/useMongoAggregate";
import { updateDoc } from "../../../firebase";
import moment from "moment";
import _, { keys } from "lodash";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import { useState } from "react";
import { useSnackbar } from "notistack";
import { useUserData } from "../../../context/UserContext";
import { calcDateColor } from "./stockConditions";

const boxStyles = {
  padding: 0,
  display: "flex",
  gap: 2,
  margin: 1,
};

const typoStyles = {
  fontWeight: "bold",
};

export const LotDates = ({
  productID,
  shelflife,
  openEditDates = false,
  setOpenEditDates,
}) => {
  const [loadingChange, setLoadingChange] = useState(false);
  const [inventoryDates, setInventoryDates] = useState({});
  const [inventoryNumbers] = useMongoAggregate({
    index: "inventorynumbers",
    aggregate: [
      {
        $match: {
          "data.onbin": { $gt: 0 },
          "data.product": productID,
        },
      },
      {
        $project: {
          expDate: "$data.expirationdate",
          expirationdate_edits: "$data.expirationdate_edits",
          bins: "$data.bins",
          onbin: "$data.onbin",
          objectID: "$data.objectID",
          _id: 0,
        },
      },
    ],
  });

  const { uid } = useUserData();
  const { enqueueSnackbar } = useSnackbar();

  if (!inventoryNumbers) return;
  if (loadingChange) return <CircularProgress />;

  const handleChangeDate = ({
    before,
    date,
    inventoryID,
    bins,
    expirationdate_edits,
  }) => {
    const value = date.format("YYYY-MM-DD");

    const binsUpdate = keys(bins).map((bin) => ({
      indexName: "bins",
      id: bin,
      hit: { [`items.${productID}.${inventoryID}.e`]: value },
    }));

    const payloadInventory = {
      expirationdate: value,
      binsUpdate,
      expirationdate_edits: [
        ...expirationdate_edits,
        {
          by: uid,
          t: (new Date() / 1000) | 0,
          before,
          after: value,
        },
      ],
    };
    setInventoryDates((prev) => ({ ...prev, [inventoryID]: payloadInventory }));
  };

  const confirmChanges = async () => {
    setLoadingChange(true);

    const updateInventory = async ([inventoryID, values]) => {
      return updateDoc({
        indexName: "inventorynumbers",
        id: inventoryID,
        hit: _.omit(values, "binsUpdate"),
      });
    };

    const updatedValues =
      Object.entries(inventoryDates).flatMap(updateInventory);

    const updatedBins = Object.values(inventoryDates).flatMap((values) => {
      return values.binsUpdate.map(async (v) => await updateDoc({ ...v }));
    });

    try {
      await Promise.all([...updatedValues, ...updatedBins]);
      location.reload();
      setOpenEditDates();
      setLoadingChange(false);
    } catch (error) {
      enqueueSnackbar("Ups! hubo un error al crear el documento.", {
        autoHideDuration: 10000,
        anchorOrigin: { horizontal: "right", vertical: "bottom" },
      });
      setLoadingChange(false);
      setOpenEditDates();
    }
  };

  const sortedInventory = inventoryNumbers.sort(
    (a, b) => moment(a.expDate).unix() - moment(b.expDate).unix(),
  );

  const reducedInventory = inventoryNumbers.reduce(
    (p, a) => ({
      ...p,
      [a.expDate]: {
        onbin: (p[a.expDate]?.onbin || 0) + (a.onbin || 0),
        expDate: a.expDate,
        utilLife: (moment(a.expDate).diff(moment(), "days") / shelflife * 100).toFixed(2)
      },
    }),
    {},
  );

  const mappedItems = () => {
    if (openEditDates) {
      return sortedInventory;
    }

    return Object.values(reducedInventory || {});
  };

  console.log(mappedItems())

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        flex: 1,
        alignItems: "flex-end",
      }}
    >
      <Typography sx={{ fontWeight: "bold" }}>Fechas vencimiento:</Typography>
      {mappedItems().map((inv) => {

        return (
          <Box key={inv.objectID}>
            <Box sx={boxStyles}>
              {openEditDates ? (
                <DatePicker
                  size="small"
                  label={"F. Vto"}
                  onChange={(date) =>
                    handleChangeDate({
                      expirationdate_edits: inv.expirationdate_edits || [],
                      date,
                      before: inv.expDate || "-",
                      inventoryID: inv.objectID,
                      bins: inv.bins,
                    })
                  }
                  defaultValue={moment(inv.expDate)}
                />
              ) : (
                <Box sx={{ display: "flex", gap: 2 }}>
                  <CalendarMonthIcon />
                  <Typography  style={{color: calcDateColor(shelflife, inv.expDate)}}>
                    {inv.expDate
                      ? moment(inv.expDate).format("DD/MM/YYYY")
                      : "-"}
                  </Typography>
                </Box>
              )}
              <Typography  sx={typoStyles}>{inv.onbin}</Typography>
              {!!inv.expDate?.length && (
                <Typography sx={typoStyles}>{inv.utilLife ? `${inv.utilLife}%` : ''}</Typography>
              )}
            </Box>
          </Box>
        );
      })}
      {openEditDates && (
        <Button
          onClick={confirmChanges}
          size="small"
          variant="outlined"
          color="primary"
        >
          Confirmar cambios
        </Button>
      )}
    </Box>
  );
};
